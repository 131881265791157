import { type ScreenViewEvent } from '@stitch-fix/event-reporter';
import dynamic from 'next/dynamic';
import { type ComponentType } from 'react';
import { type AuthenticatedClient } from '@stitch-fix/sf-next';
import { type NavProps } from '../../../../components/OnboardingVision/Nav';
import { StepWrapperProps } from '../../../../components/OnboardingVision/StepWrapper';

const flowIds = ['nu', 'xi'] as const;

export type FlowId = (typeof flowIds)[number];

export const defaultFlow: FlowId = 'nu';

export type Step = {
  step: string;
  Component: ComponentType;
  bgColor?: StepWrapperProps['bgColor'];
  navBgColor?: NavProps['bgColor'];
  hideNav?: boolean;
  screenViewName: ScreenViewEvent['name'];
  gutter?: StepWrapperProps['gutter'];
  restrictedBusinessLines?: AuthenticatedClient['businessLine'][];
};

export const flows: Record<FlowId, Step[]> = {
  nu: [
    {
      step: 'how-fixes-work',
      gutter: 'none',
      screenViewName: 'styled_for',
      Component: dynamic(
        () =>
          import('../HowFixesWork/HowFixesWork').then(
            module => module.HowFixesWork,
          ),
        { ssr: false },
      ),
      restrictedBusinessLines: ['mens', 'womens'],
    },
    {
      step: 'schedule',
      screenViewName: 'fix_frequency',
      gutter: 'narrow',
      Component: dynamic(
        () => import('../Schedule/Schedule').then(module => module.Schedule),
        { ssr: false },
      ),
    },
    {
      step: 'reservation',
      screenViewName: 'fix_date',
      gutter: 'narrow',
      Component: dynamic(
        () =>
          import('../Reservation/Reservation').then(
            module => module.Reservation,
          ),
        { ssr: false },
      ),
    },
    {
      step: 'payment',
      screenViewName: 'consolidated_shipping_payment',
      gutter: 'narrow',
      Component: dynamic(
        () =>
          import(
            '../ShippingPaymentConsolidated/ShippingPaymentConsolidated'
          ).then(module => module.ShippingPaymentConsolidated),
        { ssr: false },
      ),
    },
    {
      step: 'finalize',
      screenViewName: 'confirm_first_fix',
      Component: dynamic(
        () => import('../Finalize/Finalize').then(module => module.Finalize),
        { ssr: false },
      ),
      hideNav: true,
    },
  ],
  xi: [
    {
      step: 'how-fixes-work',
      gutter: 'none',
      screenViewName: 'styled_for',
      Component: dynamic(
        () =>
          import('../HowFixesWork/HowFixesWork').then(
            module => module.HowFixesWork,
          ),
        { ssr: false },
      ),
      restrictedBusinessLines: ['mens', 'womens'],
    },
    {
      step: 'reservation',
      screenViewName: 'fix_date',
      gutter: 'narrow',
      Component: dynamic(
        () =>
          import('../Reservation/Reservation').then(
            module => module.Reservation,
          ),
        { ssr: false },
      ),
    },
    {
      step: 'schedule',
      screenViewName: 'fix_frequency',
      gutter: 'narrow',
      Component: dynamic(
        () => import('../Schedule/Schedule').then(module => module.Schedule),
        { ssr: false },
      ),
    },
    {
      step: 'payment',
      screenViewName: 'consolidated_shipping_payment',
      gutter: 'narrow',
      Component: dynamic(
        () =>
          import(
            '../ShippingPaymentConsolidated/ShippingPaymentConsolidated'
          ).then(module => module.ShippingPaymentConsolidated),
        { ssr: false },
      ),
    },
    {
      step: 'finalize',
      screenViewName: 'confirm_first_fix',
      Component: dynamic(
        () => import('../Finalize/Finalize').then(module => module.Finalize),
        { ssr: false },
      ),
      hideNav: true,
    },
  ],
};

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import type { AuthenticatedClient } from '@stitch-fix/client-facing-auth';
import { getWindow } from '@stitch-fix/mode-react';
import { G } from '@mobily/ts-belt';
import settings from '../../settings';
import { formatMessage, DEFAULT_LEVEL, type LoggerArgs } from './common';

export const initDatadogLogger = () => {
  const win = getWindow();

  if (!win) return;

  const clientToken = settings.datadogClientToken;

  if (!clientToken) return;

  datadogLogs.init({
    clientToken,
    service: `${settings.appName}-browser`,
    forwardErrorsToLogs: false,
    sessionSampleRate: 100,
  });
};

/**
 * https://docs.datadoghq.com/logs/log_collection/javascript/#generic-logger-function
 * Logs can be found in Datadog under `service:growth-next-ui-browser`
 */
export const browserLogger = ({
  level = DEFAULT_LEVEL,
  message,
  team,
  feature,
  context,
}: LoggerArgs) => {
  datadogLogs.logger.log(
    formatMessage(message),
    { team, feature, ...context },
    level,
  );
};

/**
 * https://docs.datadoghq.com/logs/log_collection/javascript/#global-context
 */
export const addClientIdToBrowserLoggerContext = (clientId: number) => {
  const globalContext = datadogLogs.getGlobalContext();

  datadogLogs.setGlobalContext({
    ...globalContext,
    associatedClient: {
      ...(G.isObject(globalContext.associatedClient) &&
        globalContext.associatedClient),
      id: clientId,
    },
  });
};

/**
 * Client-analytics-service allows us to add RUM context only at the time of initialization,
 * with `initPerformanceAnalytics` in `_app.page.tsx`, [source](https://github.com/stitchfix/sf-next/blob/a76d2d7a2d447efe4fd6bef154fa2d7e565bf4a9/src/components/utils/analytics.ts#L56-L59)
 *
 * Since we don't have acces to the business-line during the initialization,
 * we need to use Datadog's RUM API to add the business-line to the RUM context,
 * [docs](https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#add-global-context-property)
 */
export const addBusinessLineToRumContext = (
  businessLine: AuthenticatedClient['businessLine'],
) => {
  if (!businessLine) return;

  datadogRum.setGlobalContextProperty('businessLine', businessLine);
};
